<template>
  <div id="app">
    <AppHeader/>
    <router-view/>
    <AppFooter v-if="false"/>
  </div>
</template>

<script>

  import AppHeader from './components/base/AppHeader'
  import AppFooter from './components/base/AppFooter'
  export default {
    name: 'App',
    components: {AppFooter, AppHeader}
  }
</script>

<style lang="less">
  #app {
  }

  .v-popper__popper {
    .v-popper__wrapper {
      .v-popper__inner {
        max-width: 220px;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
</style>
